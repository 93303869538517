<template>
  <div>
    <Slider />
    <Description section="international" />
    <Strengths :strengths="strengths" title="Key Services" />
    <Slogan />
    <TestimonialsPage />
  </div>
</template>

<script>
export default {
    name: "International",
       components: {
       Slider: () => import('./sections/sliders/international'),
       Description: () => import('./sections/description'),
       Strengths: () => import('./sections/strengths'),
       Slogan: () => import('./sections/slogan'),
       TestimonialsPage: () => import('../home/testimonials'),
    },

    computed: {
       strengths () {
         const data = [
            
                {
                    image: 'Pie_chart.png',
                    title: 'APIs',
                    text: 'Through the click of a button or API call, you can quickly execute various transaction types, accurately within the shortest time possible.',
                    color: '#d29f2a',
                },
                 {
                    image: 'Note.png',
                    title: 'E-KYC',
                    text: 'With Zamupay, you are able to verify the recipients before sending out the funds. We understand this is important to reduce the risk of identity theft, money laundering and the financing of criminal.',
                    color: '#d29f2a',
                },
                {
                    image: 'Note.png',
                    title: 'Reporting & Analytics',
                    text: 'Use Zamupay for your payments, financial analytics, reporting, reconciliation, tracking and auditing of your transactions.',
                    color: '#ffffff',
                },
                 {
                    image: 'Pie_chart.png',
                    title: 'Security',
                    text: 'We prioritize security over everything. We opearate at the highest level of security standards as guided by the PCI/DSS Security Standards Council. ',
                    color: '#ffffff',
                },
         ];
         return data;
       }

    },

}
</script>

<style>

</style>